@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=block);
body {
  margin: 0;
  font-family: "Helvetica Neue", "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.project-slide {
  margin: 0 70px 0 80px;
}

.card {
  border-radius: 10px;
  margin: 5px !important;
  background-color: rgba(255, 255, 255, 0.6);
}

.carousel {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.carousel-item {
  transition: -webkit-transform 0.4s ease-in-out !important;
  transition: transform 0.4s ease-in-out !important;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out !important;
}

.card-deck {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-right: 0 !important;
}

.carousel-indicators {
  display: none;
}

.card-title {
  font-weight: bold;
  margin: 0;
}

.card-text {
  margin: 0;
}

@media (max-width: 1000px) {
  .card-deck {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .card-deck {
    margin: 0;
  }

  .carousel-inner {
    padding: 5px 0px;
  }

  .project-slide {
    margin: 0;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .carousel-item {
    margin-right: auto;
    display: block;
  }

  .card {
    margin: 2px !important;
    border: none;
  }
}

.card-content {
  padding: 5px 0;
  padding-bottom: 40px;
}

.tag {
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 2px 5px;
  margin: 0 0.5rem 0 0;
}

.carousel-control-next {
  width: auto;
  margin-right: 30px;
}

.carousel-control-prev {
  width: auto;
  margin-left: 30px;
}

.experience-contents-cards {
  display: flex;
  flex-flow: row;
  max-width: 1000px;
  margin: 10px;
}

.list-group-item-action:focus, .list-group-item-action:hover, .list-group-item-action:active {
  background-color: transparent;
  background-color: initial;
}


.col-sm-4 {
  margin: 0;
  padding: 0;
  max-width: 250px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
  flex: 0 1 auto;
  border-radius: 20px 0px 0px 20px;
}

.carousel-control-next,
.carousel-control-prev {
  display: none;
  position: relative;
  margin: 0;
  padding: 30px;
  background-color: transparent;
  border: none;
}

@media (min-width: 769px) {
  .carousel-inner {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .col-sm-4 {
    overflow-y: hidden
  }

  .experience-card-contents {
    min-height: 650px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: block;
  }
}

.experience-contents {
  display: flex;
  align-items: center;
}

h1 {
  margin: 0;
  font-family: "Montserrat";
  font-size: 20px;
}


.experience-card {
  background-color: transparent;
}

.experience-card-contents {
  margin: 0 !important;
  z-index: 1000;
  background-color: transparent;
  border: none;
}

.col-sm-8 {
  margin: 0;
  padding: 0;
  flex: auto;
  max-width: none;
}

.list-group {
  border-radius: 0;
}

.company-logo {
  height: 50px;
  padding-top: 100%;
  margin-top: auto;
  margin-bottom: auto;
  box-sizing: unset;
}

footer {
  color: white;
  background-color: #343a40;
}

.project {
  background-color: #ef4e4e;
  min-height: 90vh;
  display: flex;
  align-items: center;
}

.experience {
  background-color: #2186eb;
  min-height: 96vh;
  display: flex;
  align-items: center;
}

a:focus {
  outline: none;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn:hover {
  outline: 0;
  box-shadow: none;
}

.btn:active {
  outline: 0;
  box-shadow: none;
}

.experience-card-header {
  width: 70%;
  padding: 10px 0px;
  border-bottom: 1px solid #707070;
}

.experience-card-text {
  margin: 20px 2px;
}

.list-group {
  min-height: 650px;
}

.experience-card-body {
  margin: 10px 40px;
}

.collapse:not(.show) {
  display: block;
}

.experience-show-more {
  display: block;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
}

.experience-show-more-container {
  display: none;
}

a:hover {
  text-decoration: none;
}

.experience-buttons {
  pointer-events: none;
}

#basic-navbar-nav {
  font-family: "Montserrat";
}

.navbar-toggler {
  border: none;
}

.experience-anchor-button {
  pointer-events: none;
}

@media (min-width: 769px) {
  .experience-card-body {
    padding: 0;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 20px;
  }

  .col-sm-4 {
    display: none;
  }
  .tab-pane {
    display: block !important;
  }
  .fade:not(.show) {
    opacity: 100;
  }

  .experience-card-contents {
    height: auto;
  }

  .experience-card-header {
    width: 100%;
    min-height: 110px;
    display: flex;
    align-items: center;
  }

  .experience-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0px 10px;
  }

  .experience-contents-cards {
    margin: 0;
    width: 100%;
  }

  .experience-contents {
    width: 100%;
  }

  .experience-card-header {
    border-bottom: none;
    padding: 0;
  }

  .experience-show-more {
    pointer-events: auto;
  }

  .experience-show-more-normal {
    transition: 0.3s;
  }

  .experience-show-more-rotate {
    transition: 0.3s;
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  .experience-show-more-container {
    display: flex;
    align-items: center;
  }

  .experience {
    min-height: auto;
  }

  .collapse:not(.show) {
    display: none;
  }

  .company-logo-wrapper {
    width: 80px;
    display: flex;
    align-items: center;
  }

  .experience-card-text {
    margin: 0;
  }

  .experience-show-more-container {
    display: block;
  }

  .experience-buttons {
    pointer-events: auto;
  }

  .nav-link {
    padding: 1rem 1rem !important;
  }

  .navbar-collapse {
    background-color: #222222;
    width: 100%;
  }

  .navbar {
    padding: 0;
  }

  .mobile-nav {
    padding: 0.5rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .experience-card-contents {
    min-height: auto;
  }

  .list-group {
    min-height: auto;
  }

  .experience-anchor-button {
    pointer-events: auto;
  }
}

.disable-click {
  pointer-events: none;
}

.list-group {
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.list-group-item {
  flex: 1 1;
  background-color: transparent;
}

.about-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.about-card {
  max-width: 680px;
  margin: 0 auto;
  border-radius: 20px;
  z-index: 1000;
}

.about {
  display: flex;
  align-items: center;
  padding: 80px 0px;
  background-color: #4e54c8;
}

.about-inner {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}

.about-banner {
  background-color: #eaecef;
  height: 0px;
}

.about-content {
  font-size: 19px;
  text-align: justify;
  margin: 30px;
}

.about-img {
  height: 120px;
  -webkit-transform: translateY(-35%);
          transform: translateY(-35%);
  margin-left: 30px;
  position: absolute;
}

.about-img-arrow {
  position: absolute;
  margin-left: 120px;
  -webkit-transform: translate(0%, 120%) rotate(-15deg);
          transform: translate(0%, 120%) rotate(-15deg);
}

.about-title {
  margin-top: 75px;
  margin-left: 60px;
}

h3 {
  font-size: 2rem;
  font-weight: bold;
  font-family: "Helvetica Neue";
  text-align: left;
}

h2 {
  font-size: 5rem;
  margin-left: 50px;
  margin-right: auto;
  font-weight: bold;
  padding: 0;
  font-family: "Helvetica Neue";
}

.contact {
  text-align: right;
  margin-top: 30px;
}

.contact-logos {
  margin: 0px 10px;
}

.slide-title {
  font-size: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 20px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Montserrat";
}

.experience {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0px;
}

.experience-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2000px;
}

.projects-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2000px;
}

.about-statement {
  margin: 0 auto;
}

@media (max-width: 1350px) {
  h2 {
    font-size: 3em;
  }

  .project {
    min-height: auto;
  }

  .experience-container {
    flex-direction: column;
    padding-bottom: 60px;
  }

  .projects-container {
    flex-direction: column;
    padding-bottom: 60px;
  }

  .slide-title {
    font-size: 3em;
    margin-left: 0;
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .about {
    min-height: auto;
    height: auto;
    padding: 0px;
    padding-bottom: 80px;
  }

  .experience {
    min-height: auto;
    padding: 0;
  }

  .about-inner {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1351px) {
  .experience-contents-cards {
    margin: 10px 20px 0px 40px;
  }
}

@media (max-width: 768px) {
  .project {
    display: block;
  }

  .experience {
    display: block;
  }

  .about-card {
    margin: 0;
    min-width: 100%;
    min-height: 0;
    padding-bottom: 20px;
  }

  .slide-title {
    padding: 40px 10px;
    margin-bottom: 0;
    text-align: center;
  }

  .carousel-inner {
    padding: 0px;
  }

  .about-inner {
    display: block;
  }

  h2 {
    font-size: 1rem;
    text-align: right;
    padding: 0;
    line-height: 0.7em;
  }

  .about {
    padding: 0;
  }

  .about-content {
    margin: 0px 10px;
    margin-bottom: 0px;
    font-size: 16px;
    background-attachment: scroll;
  }

  .about-statement {
    margin: 20px 10px;
  }

  .about-title {
    margin-top: 45px;
    margin-left: 63px;
    margin-bottom: 10px;
  }

  .about-img {
    -webkit-transform: translateY(-60%);
            transform: translateY(-60%);
  }

  .contact {
    margin-top: 0;
  }

  h3 {
    font-size: 1.5rem;
    margin: 0;
  }

  .about-img-arrow {
    position: absolute;
    margin-left: 115px;
    -webkit-transform: translate(0%, 55%) rotate(-8deg);
            transform: translate(0%, 55%) rotate(-8deg);
  }

  .experience-container {
    display: block;
    padding-bottom: 0px;
  }

  .projects-container {
    display: block;
    padding-bottom: 0px;
  }

  .experience-contents {
    display: block;
    margin: 0;
  }

  .last-card {
    margin-bottom: 0 !important;
  }

  .navbar-collapse {
    background-color: rgba(17, 17, 17, 0.7);
    width: 100%;
  }
}

.nav-active {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: bold !important;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.5);
}

.nav-link {
  font-weight: normal;
}

.slide-defaults {
  position: relative;
  display: block;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.make-hidden {
  visibility: hidden;
}

@media (min-width: 769px) {
  .s-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .prev-slide {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  .next-slide {
    visibility: hidden;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  .make-hidden {
    visibility: hidden;
  }
  .slide-defaults {
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    visibility: hidden;
  }

  .make-visible {
    visibility: visible;
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  .about-statement {
    display: block;
  }

  .about-inner {
    display: block;
    /* max-width: 680px; */
  }
  .about-card {
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    font-size: 1rem;
    text-align: right;
    line-height: 0.7em;
  }
}

.tag-container {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
}

.show-more-container {
  z-index: 1000;
  position: relative;
  width: 100%;
  left: 0;
  margin-top: 30px;
}

.show-more {
  color: white;
  border: 1px solid white;
  padding: 8px 15px;
  border-radius: 20px;
}
.show-more:hover {
  color: #bbbbbb;
  border: 1px solid #bbbbbb;
}

.socials {
  margin-bottom: 15px;
  margin-right: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.experience-contents-cards {
  display: flex;
}

.tab-content {
  display: flex;
}

.col-sm-8 {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 0px 20px 20px 0px;
}

.tab-content > .tab-pane {
  display: block;
  visibility: visible;
}

.tab-content {
  display: grid;
  grid-template: 1fr / 1fr;
}

.tab-content > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.list-group-item {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item.active {
  background-color: #444444;
  border-color: #444444;
}



.list-group-item-action:hover > .list-group-item-action:focus {
  color: #495057;
  
  /* background-color: none; */
}

@media (max-width: 768px) {
  .tab-content {
    display: block;
  }

  .col-sm-8 {
    background-color: transparent;
  }

  .experience-card-contents {
    margin: 2px !important;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.welcome {
  display: flex;
  align-items: center;
  background-color: #111111;
  position: relative;
}

.welcome-img {
  height: 120px;
  margin: 10px;
  border: 3px solid #ffffff;
  border-radius: 60px;
}

/* Credits: https://codepen.io/mohaiman/pen/MQqMyo */

.welcome-container {
  margin-left: auto;
  margin-right: auto;
}

.welcome-container h1 {
  text-align: center;
  color: #fff;
  font-size: 40px;
  font-family: "Montserrat";
}

.welcome-container h2 {
  text-align: center;
  color: #fff;
  font-size: 20px;
  margin: 0;
  font-family: "Montserrat";
  font-weight: normal;
}

.area {
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
